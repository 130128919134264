<template>
  <div>

    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
      disableItemsPerPage: true,
      itemsPerPageOptions: [itemsPerPage],
    }"
                  :items="elements" :loading="loading">
      <template v-slot:item.data="{item}">
        {{ item.data | moment('DD/MM/YYYY') }}
      </template>
      <template v-slot:item.tipoAluno="{item}">
        <span :class="corTipoAluno(item.tipoAluno)">{{ item.tipoAluno }}</span>
      </template>
      <template v-slot:item.idReposicao="{item}">
        {{ item.idReposicao === 0 ? "Não" : "S" }}
      </template>
      <template v-slot:item.flgPresenca="{item}">
        {{ item.flgPresenca == "0" ? "Não Informado" : (item.flgPresenca == "1" ? "Presente" : "Ausente") }}
      </template>
      
      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis. Utilize o filtro para refazer a pesquisa.</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.nome_idCliente}} <span :class="corTipoAluno(item.tipoAluno)">({{item.tipoAluno}})</span></v-list-item-title>
              <v-list-item-subtitle>{{ item.data | moment('DD/MM/YYYY') }}</v-list-item-subtitle>
              <v-list-item-subtitle>Presença: {{ item.flgPresenca == "0" ? "Não Informado" : (item.flgPresenca == "1" ? "Presente" : "Ausente") }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip color="primary" class="mr-2" input-value="false" small>{{ item.descricao_idQuadra }}</v-chip>
            </v-list-item-action-text>
            <v-list-item-action-text>
              <v-chip small input-value="false" outlined>
                {{ item.descricao_idHorario }}h
              </v-chip>
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAllUsuarioLogado} from '../../../api/frequencia';
import ResponsiveMenu from "../../app/ResponsiveMenu";
import LookupDominio from "../common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import {mapActions, mapState} from "vuex";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "MinhasFrequenciasDataTable",
  components: {LookupDominio, ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      lookup: ItemDeDominio.STS_PRESENCA,
      selectedItem: null,
      menuOptions: [],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          text: 'Tipo',
          value: 'tipoAluno',
          align: 'start',
          width: "5%"
        },
        {
          text: 'Aluno',
          value: 'nome_idCliente',
          align: 'start',
          width: "15%"
        },
        {
          text: 'Data',
          value: 'data',
          align: 'start',
          width: "10%"
        },
        {
          text: 'Dia',
          value: 'diaSemana',
          align: 'start',
          width: "10%"
        },
        {
          text: 'Horário',
          value: 'descricao_idHorario',
          align: 'start',
          width: "10%"
        },
        {
          text: 'Quadra',
          value: 'descricao_idQuadra',
          align: 'start',
          width: "10%"
        },
        {
          text: 'Aula Reposição',
          value: 'idReposicao',
          align: 'start',
          width: "10%"
        },
        {
          text: 'Status',
          value: 'flgPresenca',
          align: 'start',
          width: "10%"
        },
        {
          text: 'Obs',
          value: 'obs',
          align: 'start',
          width: "20%"
        }
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.nome_idCliente
    },
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    corTipoAluno(tipo){
      if (tipo=="Titular"){
          return "titular";
      } else {
          return "dependente";
      }
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllUsuarioLogado(this.pageOptions.page, {
          ...this.searchValues
        });

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 152px);
    }
  }
}

.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}
</style>